body {
	background: $brand-primary;
	color: #fff;
	text-align: center;
}

.body-container {
	height: 100vh;
}

.vert-center {
	@include vertical-align();
}
.phone-sss {

	font-size: 35px;
	font-weight: 800;
	margin: 30px 0;
	display: inline-block;
	color: #fff;
	text-decoration: none;
}

.btn-home {
	display: inline-block;
	margin: 15px 0;
	border: 3px solid white;
	font-size: 20px;
	border-radius: 3px;
	color: #fff;
	padding: 12px 20px;
	text-transform: uppercase;
	transition: all .2s;
	&:hover {
		color: #fff;
		background: lighten($brand-primary, 3%);
	}
}
