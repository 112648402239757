
.main-header {
	background: #fff;
	padding: 50px 0;
	text-align: center;
	a {
		padding: 7px 0px;
		width: calc(50% - 10px);
		display: inline-block;
		font-size: 21px;
		&:hover {
			text-decoration: underline;
		}
	}
	/* Dropdown Button */
.dropbtn {
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
	a {
		width: 100%;
	}
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
	background: white;
	left: -13px;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
	text-align: left;
	&:hover {
		text-decoration: underline;
	}
}


/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

	@include media("<=tablet") {
	}
}
